*, *:before, *:after {
  box-sizing:inherit;}

::selection {
  background: var(--color-selection);
}

::-moz-selection {
  background: var(--color-selection);
}

html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: var(--color-background-primary);
  font-family: var(--font-family);
  margin: 0 auto;
  line-height: 1.5;
  padding: 4vh 6vw;
  overflow-x: hidden;
  font-size:1.8rem;
  font-size: calc(1.5rem + 0.25vw);
  max-width: 56em;
  color: var(--color-primary);
}

#tooltip-wrapper {
  background: var(--color-background-primary);
  padding: 1.5em;
  border: 1px solid var(--color-outline);
  border-radius: 6px;
  overflow: scroll;
  position: absolute;
  width: 400px;
  max-width:  70vw;
  height: 350px;
  max-height: 70vh;
  font-size: 0.825em;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  opacity: 0;
  transition: opacity 100ms;
}

small {
  display: inline-block;
  font-size: var(--font-smallest);
  line-height: 1.4;
  color: var(--color-muted);
}

.small {
  font-size: var(--font-smaller);
  line-height: 1.4;
  color: var(--color-muted);
}

footer {
  margin: 2em 0;
  padding-top: 2em;
}

img {
  max-width: 100%;
  max-height: 75vh;
  border-radius: var(--border-radius);
}

iframe,
video {
  border-radius: var(--border-radius);
}

blockquote {
  padding-left: 1.5em;
  margin: 1.5em 0;
  border-left: 2px solid var(--color-primary);

  p {
    margin: 0;
  }
}

p, li {
  max-width: var(--paragraph-max-width);
}

/* Align */
.l { text-align:left;}
.r { text-align:right;}
.c { text-align:center;}
.cc { margin-left:auto; margin-right:auto;}
.vt { vertical-align:top; }

/* Borders */
.ba { border: 1px solid var(--color-outline);}
.bt { border-top: 1px solid var(--color-outline);}
.br { border-right: 1px solid var(--color-outline);}
.bb { border-bottom: 1px solid var(--color-outline);}
.bl { border-left: 1px solid var(--color-outline);}

hr {
  width: 100%;
  border: 0;
  height: 1px;
  margin: 1.5em 0;
  background: var(--color-outline);
}

/* Typography */
.muted {
  color: var(--color-muted);
}
.faint {
  color: var(--color-faint);
}

nav {
  margin: 1em 0 3em;
}

table {
  border-top: 1px solid var(--color-outline); 
  width: 100%;
  border-collapse:collapse;
  border-spacing:0;
}
tr {
  border-bottom: 1px solid var(--color-outline);
}
td {
  padding: 0.5em 0;
}

#notes-entry-container {
  display: grid;
  grid-gap: 2em;
  grid-template-areas:
    "content"
    "side";

  @media (min-width: 700px) {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "content side";
  }
}

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 1;
}

code {
  background: var(--color-background-secondary);
  padding: 0.1em 0.2em;
  border-radius: 4px;
}

.mh {
    @media (max-width: 860px) {
        display: none;
    }
}
